import React, { useContext } from "react";
import { FiLogOut } from "react-icons/fi";
import { ApiKeyContext } from "../../context/apiKey.tsx";
import { useNavigate } from "react-router-dom";

export default function disconnectWallet() {
  const { setApiKey } = useContext(ApiKeyContext);
  const navigate = useNavigate();
  return (
    <div
      className="flex space-x-2 border rounded-full border-[#1BFED7] hover:bg-[#1BFED7] dark:text-[#1BFED7] hover:text-black dark:hover:text-black cursor-pointer dark:text-white  w-full justify-center items-center rounded-md duration-200 py-4.5 xs:w-32 h-9  hover:opacity-75  w-44 text-sm  xs:text-xs xs:py-2"
      onClick={() => {
        sessionStorage.removeItem("apiKey");
        setApiKey(null);
        navigate(`/`);
      }}
    >
      <span>
        {" "}
        <FiLogOut size={20} color={""} />
      </span>

      <span className="font-semibold text-white">Logout</span>
    </div>
  );
}
