export const isExtensionInstalled = (walletName) => {
  if (
    typeof window == "undefined" ||
    window[walletName == "AlgoSigner" ? "algorand" : walletName] === undefined
  ) {
    return false;
  } else {
    return true;
  }
};
