export const navigationItems = [
  {
    title: "Home",
    link: "/",
    isExternalLink: false,
    displayArrowIcon: false,
  },
  {
    title: "Admin",
    link: "/admin",
    isExternalLink: false,
    displayArrowIcon: false,
  },
];
