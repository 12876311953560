import React, { useState } from "react";
import InputField from "../components/InputField";
import Button from "../components/Button";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export function HomePage() {
  const navigate = useNavigate();
  const BACKEND_URL = process.env.BACKEND_URL;
  const [inputData, setInputData] = useState("");

  async function getTransactionDetails(txnId) {
    if (!txnId) {
      return;
    }
    try {
      const { data } = await axios.get(
        `${BACKEND_URL}/multisig/transaction?id=${txnId}`
      );
      navigate(`/sign/${data.transactionId}`);
    } catch (error) {
      toast.error("Invalid Transaction ID.", {
        position: "top-right",
        duration: 10000,
      });
      console.log(error, "err");
    }
  }

  return (
    <div className="text-white flex flex-col  justify-center items-center  rounded-lg bg-[#232328] p-4">
      <div className="w-96 flex flex-col space-y-4">
        Enter Transaction ID{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12 text-sm pl-2"
          value={inputData}
          handleChange={(data) => {
            setInputData(data.target.value);
          }}
        />
        <Button
          title={"Find transaction"}
          onClickHandler={async () => {
            await getTransactionDetails(inputData);
          }}
          classes={"w-full"}
        />
      </div>
    </div>
  );
}
