import React from "react";
import "./TransactionDetails.css";
import Button from "../Button";
import { isExtensionInstalled } from "../../utils";
import toast from "react-hot-toast";

function TransactionDetails({ transactionDetails, onClickHandler }) {
  return (
    <div className="flex flex-col justify-center items-center">
      {" "}
      <h1 className="ml-4 text-white  tracking-tightest">
        Transaction Details
      </h1>
      <div className="Transaction-details-wrapper text-white bg-[#232328] p-4 flex flex-col">
        <div className="w-full flex flex-col space-y-4 justify-center text-md">
          <div>Name: {transactionDetails.name}</div>
          <div>Transaction ID: {transactionDetails.transactionId}</div>
          <div>Description: {transactionDetails.description}</div>
          <div>MultiSig Address: {transactionDetails.multisigAddress}</div>
          <div className="flex flex-row justify-center">
            <Button
              title={"Sign transaction"}
              onClickHandler={() => {
                let isWalletExtensionInstalled =
                  isExtensionInstalled("AlgoSigner");
                if (!isWalletExtensionInstalled) {
                  toast.error("AlgoSigner Extension not installed.", {
                    position: "top-right",
                    duration: 10000,
                  });
                  return;
                }
                onClickHandler();
              }}
              classes="w-80"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetails;
