import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";

function CreateMultiSigAccount({ apiKey }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [signatoryAddress1, setSignatoryAddress1] = useState("");
  const [signatoryAddress2, setSignatoryAddress2] = useState("");
  const [signatoryAddress3, setSignatoryAddress3] = useState("");

  async function createMultiSigAcc(multiSigDetails) {
    if (!multiSigDetails) {
      return;
    }
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };

      const { data } = await axios.post(
        `${process.env.BACKEND_URL}/multisig/account`,
        multiSigDetails,
        { headers }
      );
      toast.success(
        <div className={`flex  text-xs flex-col space-x-1`}>
          <div>Address:</div>
          <div className={`break-all text-black `}>{data.addr}</div>
        </div>,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error(<div className="text-xs">{JSON.stringify(error)}</div>, {
          position: "top-right",
          duration: 10000,
        });
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      <div>
        Enter Name{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12 pl-2"
          value={name}
          handleChange={(data) => {
            setName(data.target.value);
          }}
        />
      </div>
      <div>
        Enter description{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12  pl-2"
          value={description}
          handleChange={(data) => {
            setDescription(data.target.value);
          }}
        />
      </div>
      <div>
        Signatory's Address 1{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12 pl-2"
          value={signatoryAddress1}
          handleChange={(data) => {
            setSignatoryAddress1(data.target.value);
          }}
        />
      </div>
      <div>
        Signatory's Address 2{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12 pl-2"
          value={signatoryAddress2}
          handleChange={(data) => {
            setSignatoryAddress2(data.target.value);
          }}
        />
      </div>
      <div>
        Signatory's Address 3{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12 pl-2"
          value={signatoryAddress3}
          handleChange={(data) => {
            setSignatoryAddress3(data.target.value);
          }}
        />
      </div>
      <Button
        title={"Create MultiSig Account"}
        onClickHandler={async () => {
          await createMultiSigAcc({
            name: name,
            description: description,
            multisigParams: {
              version: 1,
              threshold: 3,
              addrs: [signatoryAddress1, signatoryAddress2, signatoryAddress3],
            },
          });
        }}
        classes="w-full"
      />
    </div>
  );
}

export default CreateMultiSigAccount;
