import React, { useState, useEffect } from "react";
import "./Accordion.css";
export default function Accordion({
  accordionTitle,
  accordionContent,
  toggleFirstStateByDefault,
  disabled,
  accordionItemIndex,
  toggleAccordion,
}) {
  const [selected, setSelected] = useState(
    toggleFirstStateByDefault ? toggleFirstStateByDefault : false
  );
  const toggle = () => {
    setSelected((v) => !v);
  };

  useEffect(() => {
    if (toggleAccordion == false) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [toggleAccordion]);
  return (
    <div className={`accordion-wrapper dark:bg-[#232328] bg-slate-100 mb-3`}>
      <div
        className={`accordion-item p-4`}
        id={`accordion-item-${accordionItemIndex}`}
      >
        <div
          className="accordion-title hover:opacity-75  tracking-tightest  xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md font-light items-center dark:text-white text-black cursor-pointer"
          onClick={() => {
            if (disabled) {
              return;
            }
            toggle();
          }}
        >
          {accordionTitle}
        </div>
        <div
          className={selected ? "accordion-content show" : "accordion-content"}
        >
          {accordionContent}{" "}
        </div>
      </div>
    </div>
  );
}
