import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";

function getAllTransactions({ apiKey }) {
  async function getAllTransactionsDetails() {
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.get(
        `${process.env.BACKEND_URL}/multisig/transactions`,
        { headers }
      );

      {
        data.map(({ _id, name, description, multisigAddress }, index) => {
          return toast.success(
            <div className="flex flex-col text-xs">
              <div className={`flex flex-row space-x-0.5 `} key={index}>
                <div>_id:</div>
                <div className={`break-all text-black `}>{_id}</div>
              </div>
              <div className={`flex flex-row space-x-0.5 `}>
                <div>name:</div>
                <div className={`break-all text-black `}>{name}</div>
              </div>
              <div className={`flex flex-row space-x-0.5 `}>
                <div>description:</div>
                <div className={`break-all text-black `}>{description}</div>
              </div>
              <div className={`flex flex-row space-x-0.5 `}>
                <div>multisigAddress:</div>
                <div
                  className={`break-all text-black underline cursor-pointer`}
                  onClick={() => {
                    window.open(
                      process.env.network === "testnet"
                        ? `https://testnet.algoexplorer.io/address/${multisigAddress}`
                        : `https://algoexplorer.io/address/${multisigAddress}`,
                      "_blank"
                    );
                  }}
                >
                  {multisigAddress}
                </div>
              </div>
            </div>,
            {
              position: "top-right",
              duration: 10000,
            }
          );
        });
      }
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error("There is some error.Please try again.", {
          position: "top-right",
          duration: 10000,
        });
        console.log(error, "err");
      }
    }
  }
  return (
    <Button
      title={"Get all transaction details"}
      onClickHandler={getAllTransactionsDetails}
      classes="w-80"
    />
  );
}

export default getAllTransactions;
