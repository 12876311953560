import React, { useContext } from "react";
import AlgomintLogo from "../../images/algomint-logo.svg";
import Logout from "../LogoutButton";
import { navigationItems } from "../../constants";
import { NavLink } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import { ApiKeyContext } from "../../context/apiKey.tsx";
import "./NavigationBar.css";

export function NavigationBar() {
  const { apiKey } = useContext(ApiKeyContext);
  return (
    <header>
      <nav
        className={`nav w-full flex flex-row gap-6 xs:max-h-28 xs:min-h-28 sm:max-h-28 sm:min-h-28 md:max-h-28 md:min-h-28 lg:max-h-28 lg:min-h-28 xl:max-h-28 xl:min-h-28 2xl:max-h-28 2xl:min-h-28 justify-between"
          }`}
      >
        <div className="flex flex-row w-full">
          <img src={AlgomintLogo} className="h-16 w-16" alt="Algomint logo" />
          <ul className={`flex menu-item justify-around p-4`}>
            {navigationItems.map(
              ({ title, link, isExternalLink, displayArrowIcon }, index) => {
                return (
                  <li
                    key={index}
                    className={`flex nav-item flex-row text-base xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md mx-2 py-2 text-black dark:text-white cursor-pointer`}
                  >
                    {
                      <>
                        {" "}
                        {isExternalLink ? (
                          <a href={link} target={"_blank"}>
                            {title}
                          </a>
                        ) : (
                          <NavLink
                            to={link}
                            className={({ isActive }) =>
                              isActive ? "nav-item-selected" : undefined
                            }
                          >
                            {" "}
                            {title}
                          </NavLink>
                        )}
                        {isExternalLink && displayArrowIcon ? (
                          <span className="px-1 mt-1">
                            <BsArrowUpRight />
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    }
                  </li>
                );
              }
            )}
          </ul>
        </div>
        {apiKey ? (
          <div className="mr-2">
            <Logout />
          </div>
        ) : null}
      </nav>
    </header>
  );
}
