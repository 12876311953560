import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";
import { algosToMicroalgos } from "algosdk/src/main";

function fundMultiSigAccount({ apiKey }) {
  const [inputData, setInputData] = useState("");
  const [amount, setAmount] = useState(0);

  async function fundMultiSigAcc(multiSigAddress, amount) {
    if (!multiSigAddress || !amount) {
      return;
    }
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.post(
        `${process.env.BACKEND_URL}/multisig/fund-multisig-account`,
        {
          multisigAddress: multiSigAddress,
          amount: algosToMicroalgos(amount),
        },
        { headers }
      );
      toast.success(
        <div className={`flex  text-xs flex-col space-x-1`}>
          <div>Address funded:</div>
          <div className={`break-all text-black `}>{data.addr}</div>
        </div>,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        if (error.response.data.includes("overspend")) {
          toast.error("Failed! Please fund FAUCET address with ALGOS.", {
            position: "top-right",
            duration: 10000,
          });
          return;
        }
        toast.error(
          "There is some error.Please enter correct transaction id.",
          {
            position: "top-right",
            duration: 10000,
          }
        );
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      <div>
        {" "}
        Enter MultiSig Address{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12  pl-2"
          value={inputData}
          handleChange={(data) => {
            setInputData(data.target.value);
          }}
        />
      </div>
      <div>
        Enter Amount{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12 pl-2"
          value={amount}
          type={"number"}
          handleChange={(data) => {
            setAmount(data.target.value);
          }}
        />
      </div>
      <Button
        title={"Fund address"}
        onClickHandler={async () => {
          if (
            window.confirm(
              "Are you sure you wish to transfer funds to the multisig account?"
            )
          ) {
            await fundMultiSigAcc(inputData, Number(amount));
          }
        }}
        classes="w-full"
      />
    </div>
  );
}

export default fundMultiSigAccount;
