import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";

function suggestedParams({ apiKey }) {
  async function getSuggestedParams() {
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.get(
        `${process.env.BACKEND_URL}/multisig/suggested-params`,
        { headers }
      );
      toast.success(
        <div className="flex flex-col w-full text-xs">
          {Object.keys(data.suggestedParams).map(
            (suggestedParamsDetail, index) => {
              return (
                <div className={`flex flex-row space-x-1`} key={index}>
                  <div>{suggestedParamsDetail}:</div>
                  <div className={`break-all text-black `}>
                    {data.suggestedParams[suggestedParamsDetail]
                      ? data.suggestedParams[suggestedParamsDetail]
                      : "unavailable"}
                  </div>
                </div>
              );
            }
          )}
        </div>,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error(JSON.stringify(error), {
          position: "top-right",
          duration: 10000,
        });
        console.log(error, "err");
      }
    }
  }
  return (
    <Button
      title={"Get suggested params"}
      onClickHandler={getSuggestedParams}
      classes="w-80"
    />
  );
}

export default suggestedParams;
