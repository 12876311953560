import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";

function CreateMultiSigTransaction({ apiKey }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [multisigAddress, setMultiSigAddress] = useState("");
  const [base64Txn, setBase64Txn] = useState("");
  async function CreateMultiSigTxn(txn) {
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.post(
        `${process.env.BACKEND_URL}/multisig/transaction`,
        {
          name: txn.name,
          description: txn.description,
          multisigAddress: txn.multisigAddress,
          txn: txn.base64Txns,
        },
        { headers }
      );
      toast.success(
        <div className="flex flex-col w-full text-xs">
          <div className={`flex flex-row space-x-1`}>
            <div>Transaction Id:</div>
            <div className={`break-all text-black `}>
              {data["transactionId"]}
            </div>
          </div>
        </div>,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error("There is some error.Please enter correct details.", {
          position: "top-right",
          duration: 10000,
        });
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      <div>
        Enter name{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12 pl-2"
          value={name}
          handleChange={(data) => {
            setName(data.target.value);
          }}
        />
      </div>
      <div>
        {" "}
        Enter Description{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12  pl-2"
          value={description}
          handleChange={(data) => {
            setDescription(data.target.value);
          }}
        />
      </div>
      <div>
        {" "}
        Enter multisig Address{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12  pl-2"
          value={multisigAddress}
          handleChange={(data) => {
            setMultiSigAddress(data.target.value);
          }}
        />
      </div>
      <div>
        {" "}
        Paste Base64Txns{" "}
        <InputField
          placeholder={"Paste Base64Txn array"}
          classes="w-full h-12  pl-2"
          value={base64Txn}
          handleChange={(data) => {
            setBase64Txn(data.target.value);
          }}
        />
      </div>
      <Button
        title={"Create multisig transaction"}
        onClickHandler={async () => {
          let txn = {
            name: name,
            description: description,
            multisigAddress: multisigAddress,
            base64Txns: base64Txn,
          };
          await CreateMultiSigTxn(txn);
        }}
        classes="w-full"
      />
    </div>
  );
}

export default CreateMultiSigTransaction;
