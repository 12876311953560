import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import * as msgpack from "algo-msgpack-with-bigint";
import { decodeSignedTransaction, encodeAddress } from "algosdk/src/main";
import TransactionDetails from "../components/TransactionDetails";
import toast from "react-hot-toast";

const BACKEND_URL = process.env.BACKEND_URL;

console.log(`Connecting to backend at ${BACKEND_URL}`);

declare const AlgoSigner: any;

interface ISignProps {
  //
  // The ID of the transaction being signed.
  //
  transactionId: string;
}

interface ISignState {
  //
  // Details of the transaction the user is signing.
  //
  transactionDetails?: any;
}

//
// Creates a page in the frontend for the user to partially sign a multisig transaction.
//
class Sign extends React.Component<ISignProps, ISignState> {
  constructor(props: ISignProps) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    await this.loadTransaction();
  }

  //
  // Loads the transaction details into state.
  //
  private async loadTransaction() {
    const { data } = await axios.get(
      `${BACKEND_URL}/multisig/transaction?id=${this.props.transactionId}`
    );

    console.log(`Retreived transaction ${this.props.transactionId}`);
    console.log(data);

    this.setState({
      transactionDetails: data,
    });
  }

  //
  // Signs the transaction using AlgoSigner.
  //
  private async signTransactionWithAlgoSigner() {
    // await AlgoSigner.connect({ ledger: "Sandbox" });
    await algorand.enable();
    const txnsToSign = this.state.transactionDetails.base64Txn.map((txn) => ({
      txn: txn,
      msig: this.state.transactionDetails.multisigParams,
    }));

    let signedTxs = await AlgoSigner.signTxn(txnsToSign);

    // Get the base64 encoded signed transaction and convert it to binary
    let binarySignedTxns = signedTxs.map((signedTxn) =>
      AlgoSigner.encoding.base64ToMsgpack(signedTxn.blob)
    );

    // Just for debugging:
    const unpackedTxns = binarySignedTxns.map((binarySignedTx) =>
      msgpack.decode(binarySignedTx)
    );
    console.log(unpackedTxns);

    // Just for debugging:
    const decodedTxns = binarySignedTxns.map((binarySignedTx) =>
      decodeSignedTransaction(binarySignedTx)
    );
    console.log(decodedTxns);

    const subsigs = decodedTxns.map((txn) => txn.msig.subsig).flat();

    return subsigs
      .filter((subsig) => subsig.s !== undefined) // Filter out sub sigs that don't have a sig.
      .map((subsig) => ({
        addr: encodeAddress(subsig.pk),
        pk: Buffer.from(subsig.pk).toString("base64"),
        s: Buffer.from(subsig.s).toString("base64"),
      }));
  }

  //
  // Allows the user to sign the transaction.
  //
  private onSignTransaction = async () => {
    try {
      const signatures = await this.signTransactionWithAlgoSigner();
      const signResponse = await axios.post(
        `${BACKEND_URL}/multisig/sign?id=${this.props.transactionId}`,
        {
          signatures: signatures,
        }
      );
      if (signResponse.status == 200) {
        toast.success("Transaction Successful.", {
          position: "top-right",
          duration: 10000,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.code == 4100 || 4001) {
        return;
      }
      toast.error(`${error}`, {
        position: "top-right",
        duration: 10000,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.transactionDetails ? (
          <TransactionDetails
            transactionId={this.props.transactionId}
            transactionDetails={this.state.transactionDetails}
            onClickHandler={this.onSignTransaction}
          />
        ) : null}
      </>
    );
  }
}

export function SignPage() {
  const params = useParams();
  return <Sign transactionId={params.transactionId as string} />;
}
