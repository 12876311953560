import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";

function GetTransactionByID({ apiKey }) {
  const [inputData, setInputData] = useState("");
  async function getTxnDetailsByID(txnId) {
    if (!txnId) {
      return;
    }
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.get(
        `${process.env.BACKEND_URL}/multisig/transaction?id=${txnId}`,
        { headers }
      );
      toast.success(
        <div className="flex flex-col w-full text-xs">
          {Object.keys(data)
            .filter((keyName) => keyName !== "base64Txn")
            .map((txDetail, index) => {
              return txDetail == "multisigParams" ? (
                <div className={`flex flex-col`} key={index}>
                  <div>multisigParams:</div>
                  <div className={`flex flex-row`}>
                    <div>version:</div>
                    <div className={`break-all text-black `}>
                      {data[txDetail].version}
                    </div>
                  </div>
                  <div className={`flex flex-row`}>
                    <div>threshold:</div>
                    <div className={`break-all text-black `}>
                      {data[txDetail].threshold}
                    </div>
                  </div>
                  <div className={`flex flex-row`}>
                    <div>Addresses:</div>
                    <div className={`text-black flex flex-col break-all`}>
                      {data[txDetail].addrs.map((addresses, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              window.open(
                                process.env.network === "testnet"
                                  ? `https://testnet.algoexplorer.io/address/${addresses}`
                                  : `https://algoexplorer.io/address/${addresses}`,
                                "_blank"
                              );
                            }}
                          >
                            {addresses}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : txDetail == "signatures" ? (
                <div className={`flex flex-row`}>
                  <div>Signatures:</div>
                  <div className={`text-black flex flex-col break-all`}>
                    {!data[txDetail].length ? (
                      <div>No signatures</div>
                    ) : (
                      data[txDetail].map((signature, id) => {
                        return <div key={id}>{signature}</div>;
                      })
                    )}
                  </div>
                </div>
              ) : (
                <div className={`flex flex-row space-x-1`} key={index}>
                  <div>{txDetail}:</div>
                  <div className={`break-all text-black `}>
                    {data[txDetail]}
                  </div>
                </div>
              );
            })}
        </div>,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error(
          "There is some error.Please enter correct transaction id.",
          {
            position: "top-right",
            duration: 10000,
          }
        );
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      Enter Transaction ID{" "}
      <InputField
        placeholder={"Enter here"}
        classes="w-full h-12 pl-2"
        value={inputData}
        handleChange={(data) => {
          setInputData(data.target.value);
        }}
      />
      <Button
        title={"Get details"}
        onClickHandler={async () => {
          await getTxnDetailsByID(inputData);
        }}
        classes="w-full"
      />
    </div>
  );
}

export default GetTransactionByID;
