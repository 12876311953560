import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";

function getCompletedTransactionDetailsByID({ apiKey }) {
  const [inputData, setInputData] = useState("");
  async function getCompletedTxnDetailsByID(txnId) {
    if (!txnId) {
      return;
    }
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.get(
        `${process.env.BACKEND_URL}/multisig/completed-transaction?id=${txnId}`,
        { headers }
      );
      if (!data.completedTransaction) {
        toast.error(
          "This transaction has not been completed.Please reach out to signatories to complete the tranasction",
          {
            position: "top-right",
            duration: 10000,
          }
        );
        return;
      }
      toast.success(
        <div className="flex flex-col w-full text-xs">
          {Object.keys(data.completedTransaction).map((txnDetail, index) => {
            return (
              <div
                className={`flex flex-row space-x-0.5 ${
                  txnDetail == "base64Txn" ? "hidden" : ""
                }`}
                key={index}
              >
                <div>{txnDetail}:</div>
                <div
                  onClick={() => {
                    if (txnDetail == "txnId") {
                      window.open(
                        process.env.network === "testnet"
                          ? `https://testnet.algoexplorer.io/tx/${data.completedTransaction[txnDetail]}`
                          : `https://algoexplorer.io/tx/${data.completedTransaction[txnDetail]}`,
                        "_blank"
                      );
                    }
                    if (txnDetail == "multisigAddress") {
                      window.open(
                        process.env.network === "testnet"
                          ? `https://testnet.algoexplorer.io/address/${data.completedTransaction[txnDetail]}`
                          : `https://algoexplorer.io/address/${data.completedTransaction[txnDetail]}`,
                        "_blank"
                      );
                    }
                  }}
                  className={`break-all text-black  ${
                    txnDetail == "txnId" || txnDetail == "multisigAddress"
                      ? "cursor-pointer underline"
                      : ""
                  }`}
                >
                  {data.completedTransaction[txnDetail]
                    ? data.completedTransaction[txnDetail]
                    : "unavailable"}
                </div>
              </div>
            );
          })}
        </div>,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error(
          "There is some error.Please enter correct transaction id.",
          {
            position: "top-right",
            duration: 10000,
          }
        );
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      Enter Transaction ID{" "}
      <InputField
        placeholder={"Enter here"}
        classes="w-full h-12  pl-2"
        value={inputData}
        handleChange={(data) => {
          setInputData(data.target.value);
        }}
      />
      <Button
        title={"Get details"}
        onClickHandler={async () => {
          await getCompletedTxnDetailsByID(inputData);
        }}
        classes="w-full"
      />
    </div>
  );
}

export default getCompletedTransactionDetailsByID;
