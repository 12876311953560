import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";

function getMultiSigParametersByAddress({ apiKey }) {
  const [inputData, setInputData] = useState("");
  async function getMultiSigParameters(multiSigAddress) {
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.get(
        `${process.env.BACKEND_URL}/multisig/account?address=${multiSigAddress}`,
        { headers }
      );
      toast.success(
        <div className="flex flex-col w-full text-xs">
          <div className={`flex flex-row space-x-1`}>
            <div>name:</div>
            <div className={`break-all text-black `}>{data.name}</div>
          </div>
          <div className={`flex flex-row space-x-1`}>
            <div>description:</div>
            <div className={`break-all text-black `}>{data.description}</div>
          </div>
          <div className={`flex flex-col`}>
            <div>multisigParams:</div>
            <div className={`flex flex-row`}>
              <div>version:</div>
              <div className={`break-all text-black `}>
                {data.multisigParams.version}
              </div>
            </div>
            <div className={`flex flex-row`}>
              <div>threshold:</div>
              <div className={`break-all text-black `}>
                {data.multisigParams.threshold}
              </div>
            </div>
            <div className={`flex flex-row`}>
              <div>Addresses:</div>
              <div className={`text-black flex flex-col break-all`}>
                {data.multisigParams.addrs.map((addresses, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        window.open(
                          process.env.network === "testnet"
                            ? `https://testnet.algoexplorer.io/address/${addresses}`
                            : `https://algoexplorer.io/address/${addresses}`,
                          "_blank"
                        );
                      }}
                    >
                      {addresses}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error(
          "There is some error.Please enter correct multisig address.",
          {
            position: "top-right",
            duration: 10000,
          }
        );
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      Enter Multisig Address{" "}
      <InputField
        placeholder={"Enter here"}
        classes="w-full h-12  pl-2"
        value={inputData}
        handleChange={(data) => {
          setInputData(data.target.value);
        }}
      />
      <Button
        title={"Get details"}
        onClickHandler={async () => {
          await getMultiSigParameters(inputData);
        }}
        classes="w-full"
      />
    </div>
  );
}

export default getMultiSigParametersByAddress;
