import React, { useEffect, useContext } from "react";
import { ApiKeyContext } from "../context/apiKey.tsx";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Accordion from "../components/Accordion";
import CreateFundedAccount from "../components/CreateFundedAccount";
import CreateMultiSigAccount from "../components/CreateMultiSigAccount";
import FundMultiSigAccount from "../components/FundMultiSigAccount";
import SuggestedParams from "../components/SuggestedParams";
import CreateExampleTransaction from "../components/CreateExampleTransaction";
import CreateMultiSigTransaction from "../components/CreateMultiSigTransaction";
import GetTransactionByID from "../components/GetTransactionByID";
import GetCompletedTransactionByID from "../components/GetCompletedTransactionByID";
import GetMultiSigParametersByAddress from "../components/GetMultiSigParametersByAddress";
import GetAllTransactions from "../components/GetAlltransactions";
import SubmitTransaction from "../components/SubmitTransaction";
import ListMultiSigByAddress from "../components/ListMultiSigByAddress";
import ListTransactionsByAddress from "../components/ListTransactionsByAddress";

export function AdminPage() {
  const navigate = useNavigate();
  const { apiKey, setApiKey } = useContext(ApiKeyContext);
  useEffect(() => {
    const apiKeyInput = async () => {
      await Swal.fire({
        title: "Enter API key",
        input: "text",
        inputLabel: "Your API key",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "You need to type API key!";
          }
          setApiKey(value);
          sessionStorage.setItem("apiKey", value);
        },
      });
      if (!sessionStorage.getItem("apiKey")) {
        navigate(`/`);
      }
    };
    if (!sessionStorage.getItem("apiKey")) {
      apiKeyInput();
    } else {
      setApiKey(sessionStorage.getItem("apiKey"));
    }
  }, []);

  const accordionData = [
    {
      name: "Create funded account",
      content: (
        <CreateFundedAccount apiKey={sessionStorage.getItem("apiKey")} />
      ),
    },
    {
      name: "Create multiSig account",
      content: (
        <CreateMultiSigAccount apiKey={sessionStorage.getItem("apiKey")} />
      ),
    },
    {
      name: "Fund multiSig account",
      content: (
        <FundMultiSigAccount apiKey={sessionStorage.getItem("apiKey")} />
      ),
    },
    {
      name: "Get suggested params",
      content: <SuggestedParams apiKey={sessionStorage.getItem("apiKey")} />,
    },
    {
      name: "Create example transaction",
      content: (
        <CreateExampleTransaction apiKey={sessionStorage.getItem("apiKey")} />
      ),
    },
    {
      name: "Create multiSig transaction",
      content: (
        <CreateMultiSigTransaction apiKey={sessionStorage.getItem("apiKey")} />
      ),
    },
    {
      name: "Get transaction details by ID",
      content: <GetTransactionByID apiKey={sessionStorage.getItem("apiKey")} />,
    },
    {
      name: "Get completed transaction details",
      content: (
        <GetCompletedTransactionByID
          apiKey={sessionStorage.getItem("apiKey")}
        />
      ),
    },
    {
      name: "Get multiSigParameters by address",
      content: (
        <GetMultiSigParametersByAddress
          apiKey={sessionStorage.getItem("apiKey")}
        />
      ),
    },
    {
      name: "Get all transactions",
      content: <GetAllTransactions apiKey={sessionStorage.getItem("apiKey")} />,
    },
    {
      name: "Submit transaction",
      content: <SubmitTransaction apiKey={sessionStorage.getItem("apiKey")} />,
    },
    {
      name: "List multiSigs by address",
      content: (
        <ListMultiSigByAddress apiKey={sessionStorage.getItem("apiKey")} />
      ),
    },
    {
      name: "List transactions by address",
      content: (
        <ListTransactionsByAddress apiKey={sessionStorage.getItem("apiKey")} />
      ),
    },
  ];
  return (
    <div className="flex justify-center items-center">
      <div className="w-130">
        {accordionData.map(({ name, content }, index) => {
          return (
            <Accordion
              key={index}
              accordionTitle={name}
              accordionContent={content}
              accordionItemIndex={index}
              toggleFirstStateByDefault={index == 0 ? true : false}
              toggleAccordion={true}
              disabled={false}
            />
          );
        })}
      </div>
    </div>
  );
}
