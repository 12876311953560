import React, { useState } from "react";
import axios from "axios";
import { algosToMicroalgos } from "algosdk/src/main";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";

function CreateFundedAccount({ apiKey }) {
  const [amount, setAmount] = useState(0);

  async function createFundedAcc(amount) {
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.post(
        `${process.env.BACKEND_URL}/multisig/create-funded-account`,
        { amount: algosToMicroalgos(amount) },
        { headers }
      );

      toast.success(
        <div className="flex flex-col w-full text-xs">
          {Object.keys(data).map((keyName, index) => {
            return (
              <div className={`flex flex-row space-x-1`} key={index}>
                <div>{keyName}:</div>
                <div className={`break-all text-black `}>{data[keyName]}</div>
              </div>
            );
          })}
        </div>,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        if (error.response.data.includes("overspend")) {
          toast.error("Failed! Please fund FAUCET address with ALGOS.", {
            position: "top-right",
            duration: 10000,
          });
          return;
        }
        toast.error(<div className="text-xs">{JSON.stringify(error)}</div>, {
          position: "top-right",
          duration: 10000,
        });
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      Enter Amount{" "}
      <InputField
        placeholder={"Enter here"}
        classes="w-full h-12 pl-2"
        value={amount}
        type={"number"}
        handleChange={(data) => {
          setAmount(data.target.value);
        }}
      />
      <Button
        title={"Create Funded Account"}
        onClickHandler={async () => {
          if (
            window.confirm(
              "Are you sure you want to create a new funded account?"
            )
          ) {
            await createFundedAcc(Number(amount));
          }
        }}
        classes="w-80"
      />
    </div>
  );
}

export default CreateFundedAccount;
