import React from "react";
import "./InputField.css";

export default function InputField({
  placeholder,
  handleChange,
  value,
  classes,
  type,
}) {
  return (
    <input
      type={type ? type : "text"}
      value={value}
      className={`text-white rounded-md shadow-md border text-xs border-white focus:outline-double bg-[#232328] p-2 ${classes}`}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
}
