import React from "react";

function DisplayMultiSigDetails({ multisigDetails }) {
  return (
    <div className="flex flex-col w-full text-xs">
      {multisigDetails.map((txnDetail) => {
        return Object.keys(txnDetail)
          .filter((keyName) => keyName !== "base64Txn")
          .map((txDetail, index) => {
            return txDetail == "multisigParams" ? (
              <div className={`flex flex-col`} key={index}>
                <div>multisigParams:</div>
                <div className={`flex flex-row`}>
                  <div>version:</div>
                  <div className={`break-all text-black `}>
                    {txnDetail[txDetail].version}
                  </div>
                </div>
                <div className={`flex flex-row`}>
                  <div>threshold:</div>
                  <div className={`break-all text-black `}>
                    {txnDetail[txDetail].threshold}
                  </div>
                </div>
                <div className={`flex flex-row`}>
                  <div>Addresses:</div>
                  <div className={`text-black flex flex-col break-all`}>
                    {txnDetail[txDetail].addrs.map((addresses, id) => {
                      return (
                        <div
                          key={id}
                          onClick={() => {
                            window.open(
                              process.env.network === "testnet"
                                ? `https://testnet.algoexplorer.io/address/${addresses}`
                                : `https://algoexplorer.io/address/${addresses}`,
                              "_blank"
                            );
                          }}
                        >
                          {addresses}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : txDetail == "signatures" ? (
              <div className={`flex flex-row`}>
                <div>Signatures:</div>
                <div className={`text-black flex flex-col break-all`}>
                  {!txnDetail[txDetail].length ? (
                    <div>No signatures</div>
                  ) : (
                    txnDetail[txDetail].map((signature, i) => {
                      return <div key={i}>{signature}</div>;
                    })
                  )}
                </div>
              </div>
            ) : (
              <div className={`flex flex-row space-x-1`} key={index}>
                <div>{txDetail}:</div>
                <div className={`break-all text-black `}>
                  {txnDetail[txDetail]}
                </div>
              </div>
            );
          });
      })}
    </div>
  );
}

export default DisplayMultiSigDetails;
