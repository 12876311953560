import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";

function CreateExampleTransaction({ apiKey }) {
  const [senderAddress, setSenderAddress] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  async function createExampleTxn(senderAddr, receiverAddr) {
    if (!senderAddr || !receiverAddr) {
      return;
    }
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.post(
        `${process.env.BACKEND_URL}/multisig/create-example-transaction`,
        {
          senderAddress: senderAddr,
          receiverAddress: receiverAddr,
        },
        { headers }
      );
      toast.success(
        <div className="flex flex-col w-full text-xs break-all my-2">
          {JSON.stringify(data.base64Txns)}
        </div>,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error(<div className="text-xs">{JSON.stringify(error)}</div>, {
          position: "top-right",
          duration: 10000,
        });
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      <div>
        Enter Sender Address{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12 pl-2"
          value={senderAddress}
          handleChange={(data) => {
            setSenderAddress(data.target.value);
          }}
        />
      </div>
      <div>
        Enter Receiver Address{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12  pl-2"
          value={receiverAddress}
          handleChange={(data) => {
            setReceiverAddress(data.target.value);
          }}
        />
      </div>
      <Button
        title={"Create Transaction"}
        onClickHandler={async () => {
          await createExampleTxn(senderAddress, receiverAddress);
        }}
        classes="w-full"
      />
    </div>
  );
}

export default CreateExampleTransaction;
