import { createContext, useState } from "react";

const ApiKeyContext = createContext();

const ApiKeyProvider = ({ children }) => {
  const [apiKey, setApiKey] = useState(null);
  return (
    <ApiKeyContext.Provider value={{ apiKey, setApiKey }}>
      {children}
    </ApiKeyContext.Provider>
  );
};

export { ApiKeyContext, ApiKeyProvider };
