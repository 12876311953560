import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";
import DisplayMultiSigDetails from "../DisplayMultiSigDetails";

function listMultiSigByAddress({ apiKey }) {
  const [inputData, setInputData] = useState("");
  async function listMultiSigsByAddress(multisigAddress) {
    if (!multisigAddress) {
      return;
    }
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.get(
        `${process.env.BACKEND_URL}/multisig/account/find?address=${multisigAddress}`,
        { headers }
      );
      toast.success(
        <DisplayMultiSigDetails multisigDetails={data.multisigs} />,
        {
          position: "top-right",
          duration: 10000,
        }
      );
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error(
          "There is some error.Please enter correct multisig address.",
          {
            position: "top-right",
            duration: 10000,
          }
        );
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      Enter Signatory's Address{" "}
      <InputField
        placeholder={"Enter here"}
        classes="w-full h-12 pl-2"
        value={inputData}
        handleChange={(data) => {
          setInputData(data.target.value);
        }}
      />
      <Button
        title={"Get details"}
        onClickHandler={async () => {
          await listMultiSigsByAddress(inputData);
        }}
        classes="w-full"
      />
    </div>
  );
}

export default listMultiSigByAddress;
