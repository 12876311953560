import React from "react";
import { ApiKeyProvider } from "./context/apiKey";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home";
import { SignPage } from "./pages/sign";
import { AdminPage } from "./pages/admin";
import { NavigationBar } from "./components/NavigationBar";
import Layout from "./components/Layout";
import { Toaster } from "react-hot-toast";

export function App() {
  return (
    <>
      <ApiKeyProvider>
        <Layout>
          <BrowserRouter>
            <NavigationBar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/sign/:transactionId" element={<SignPage />} />
            </Routes>
          </BrowserRouter>
        </Layout>
        <Toaster
          containerStyle={{
            overflowY: "scroll",
          }}
        />
      </ApiKeyProvider>
    </>
  );
}
