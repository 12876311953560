import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../Button";
import InputField from "../InputField";

function SubmitTransaction({ apiKey }) {
  const [transactionId, setTransactionId] = useState("");
  async function submitTxn(transactionId) {
    if (!transactionId) {
      return;
    }
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-key": apiKey,
        network: process.env.network,
      };
      const { data } = await axios.post(
        `${process.env.BACKEND_URL}/multisig/submit?id=${transactionId}`,
        { headers }
      );
      toast.success(JSON.stringify(data), {
        position: "top-right",
        duration: 10000,
      });
    } catch (error) {
      if (error.response.data) {
        if (error.response.data == "Forbidden") {
          toast.error(
            "Incorrect API key! Please enter correct API key and try again.",
            {
              position: "top-right",
              duration: 10000,
            }
          );
          return;
        }
        toast.error(
          "There is some error.Please enter correct transaction id.",
          {
            position: "top-right",
            duration: 10000,
          }
        );
        console.log(error, "err");
      }
    }
  }
  return (
    <div className="w-96 flex flex-col space-y-4 text-white">
      <div>
        Enter Transaction Id{" "}
        <InputField
          placeholder={"Enter here"}
          classes="w-full h-12 pl-2"
          value={transactionId}
          handleChange={(data) => {
            setTransactionId(data.target.value);
          }}
        />
      </div>
      <Button
        title={"Submit transaction"}
        onClickHandler={async () => {
          await submitTxn(transactionId);
        }}
        classes="w-full"
      />
    </div>
  );
}

export default SubmitTransaction;
